import React, { useEffect, useMemo, useState } from 'react';
import { Auction } from '@/api';

interface AuctionPricePromotionProps {
    auction?: Auction;
    children: (props: { isPromoted: true; amount: string; promotedAmount: string } | { isPromoted: false; amount: string; promotedAmount?: undefined }) => React.ReactNode;
}

const AuctionPricePromotion: React.FC<AuctionPricePromotionProps> = ({ auction, children }) => {
    const promotion = auction?.promotion;
    const amount = auction?.buy_now_amount;

    // Determine the promotion amount and expiration date
    const promotedAmount = promotion?.amount || '0.00';
    const expirationDate = useMemo(() => promotion?.expiration ? new Date(promotion.expiration) : null, [promotion]);

    // State to track if the promotion is active
    const [isPromoted, setIsPromoted] = useState(() => {
        if (expirationDate) {
            return expirationDate > new Date();
        }
        return false;
    });

    useEffect(() => {
        if (!expirationDate) return;

        // Function to check promotion status
        const checkPromotionStatus = () => {
            setIsPromoted(expirationDate > new Date());
        };

        // Calculate the remaining time until the promotion expires
        const timeUntilExpiration = expirationDate.getTime() - Date.now();

        // Update promotion status at expiration
        const timer = timeUntilExpiration > 0 ? setTimeout(checkPromotionStatus, timeUntilExpiration) : null;

        // Cleanup the timer when the component unmounts
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [expirationDate]);

    if (!auction || !amount) {
        return null;
    }

    if (isPromoted) {
        return children({
            isPromoted: true,
            amount,
            promotedAmount,
        });
    }

    return children({
        isPromoted: false,
        amount
    });
};

export default AuctionPricePromotion;
